@media (max-width: 1240px) {
  .CareerSection {
    flex-direction: column !important;
    padding: 3% 8% !important;
  }
  .MainContainer {
    gap: 10px !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    height: auto !important;
  }
  .Project{
    padding: 40px 8% !important;

  }
  .DetailSection {
    height: auto !important;
    padding: 10px 15px !important;
  }
  .DetailLAbel {
    flex-direction: column !important;
    width: auto !important;
  }
  .ProjectTitle {
    font-size: 1.75rem !important;
    align-self: center !important;
  }
  .Li {
    font-size: 22px !important;
    padding-top: 10px !important;
    word-break: keep-all !important;
  }
.ApplicationBox{
    gap: 40px !important;
}
}
